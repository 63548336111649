.conversation {
  border-left: 3px solid #21c8aa !important;
  /* border-right: none; */
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: #f3f9ff !important;
}

.ui.icon.input.type-message > input {
  border-radius: 50px !important;
  border: none !important;
  background: white !important;
}

.chatss:first-letter {
  text-transform: capitalize;
}

.type-message input {
  border-radius: 22px !important;
}

.userDp {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 45px !important;
  width: 45px !important;
  color: white;
  margin: 3px 0px 0px 10px;
}
.message-box-card {
  padding: 10px 15px !important;
  margin: 1px 1px 3px 4px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px 0px !important;
  margin-bottom: 15px !important;
}

.chat-box-card {
  box-shadow: none !important;
  background: rgb(244, 244, 244) !important;
  height: 71vh !important;
  border-radius: 0px !important;
  overflow: scroll !important;
}

@media (min-width: 1441px) {
  .chat-container {
    padding: 0% 4%;
  }
}
@media (max-width: 1441px) {
  .chat-container {
    /* padding: 0% 4%; */
    width: 100%;
  }
}

#conversation-box {
  margin: 0em 0em !important;
}
