@font-face {
  /* font-family: "Matter"; */
  src: url("../src/fontsss/Matter-Regular.woff2");
  font-family: Matter;
}
* {
  font-family: Matter !important;
}

:root {
--text: #645B5B;
--grey: #B3B3B3;
--blue: #003458;
--green: #21C8AA;
--light-blue: #C8EBFF;
}

i.icon {
  font-family: "Icons" !important;
}

.topleft {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

/* .ui.fluid.container {
  padding: 2% 8%;
} */

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #21c8aa;
  border-bottom: 12px solid #21c8aa;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background: white;
  color: #003458;
  font-family: "Matter" !important;
  height: 0vh !important;
}

#headerbackground {
  /* background: ;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
  background: #21c8aa;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  color: white;
}

#buttoncolor {
  background: #21c8aa !important;
  color: white;
}

#yourBtn {
  position: relative;
  /* top: 150px; */
  font-family: calibri;
  /* height: 180px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  /* width: 400px;   */
  padding: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #bbb;
  text-align: center;
  /* background-color: #b5cc18; */
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.bluecol {
  color: #003458 !important;
}

.greencol {
  color: #21c8aa !important;
}

.graphscore {
  background: #b2d431;
  border-radius: 20px;
  padding: 15px;
  margin: 1rem !important;
  /* display: inline-table; */
}

.ui.cards > .card > .content,
.ui.card > .content {
  border-top: none;
}

.backicon-card {
  padding: 12% !important;
  background: rgb(0, 52, 88) !important;
  color: white !important;
  margin: auto;
  /* padding-top: 35% !important; */
  height: 50px !important;
  width: 50px !important;
  border-radius: 4px !important;
}

.scroll {
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.displayflex {
  display: flex;
  padding: 0 !important;
}

.mr-auto {
  margin: auto !important;
}

.divid {
  width: 27px !important;
  display: inline-block !important;
  opacity: 0.7 !important;
  border-top: 2px solid #a4bad8 !important;
}

.ui.progress .bar {
  background: #21c8aa !important;
}

.greencolor {
  color: #21c8aa;
}

.greyback {
  background: #f1f4f8 !important;
}

.cardui {
  background: #ffffff !important;
  /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important; */
  border-radius: 2px 2px 0px 0px !important;
  border-top: 4px solid #003458 !important;
  box-shadow: 0.3px 0.3px 4.9px rgb(0 0 0 / 10%),
    0px 1.6px 3.6px rgb(0 0 0 / 13%) !important;
}

.rc-slider-track {
  position: absolute !important;
  height: 8px !important;
  background-color: #21c8aa !important;
  border-radius: 6px !important;
}
.rc-slider-dot {
  position: absolute !important;
  bottom: -2px !important;
  width: 8px !important;
  height: 8px !important;
  vertical-align: middle !important;
  background-color: transparent !important;
  border: 0px solid #e9e9e9 !important;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}
.rc-slider-handle {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-top: -3px !important;
  background-color: #21c8aa !important;
  border: solid 2px #21c8aa !important;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 1 !important;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 8px !important;
  background-color: #003458 !important;
  border-radius: 6px !important;
}

.sidemenu-width {
  /* width: 100% !important; */
  /* text-align: center !important; */
  position: fixed;
  top: 45%;
  background: #f1f4f8 !important;
  width: 50px !important;
  text-align: center;
}

.name {
  font-family: Matter !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* text-align: center; */
}
/* 
.fontsize21 {
  font-size: 21px !important;
} */

.font24px {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: Matter;
}
.font18px {
  font-size: 16px !important;
  font-family: Matter;
  font-weight: 400 !important;
}
.font21px {
  font-size: 18px !important;
  font-family: Matter;
  font-weight: 400 !important;
}

.font14px {
  font-size: 14px !important;
  font-family: Matter;
  font-weight: 400 !important;
}

.green-sub-head {
  font-family: "Matter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.full-name {
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}

.ui.input.basic-info-contect > input {
  padding: 0.37857143em 1em !important;
  background: #f4f6f9;
  border: 0px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  opacity: 1 !important;
  text-align: center !important;
}

.ui.input.basic-info-center > input {
  text-align: center !important;
}

.ui.icon.input > input {
  padding-right: 2.67142857em !important;
}

.labelskill {
  /* background: #1786d0 !important;
  color: white !important; */
  font-family: "Matter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid #003458 !important;
  background: none !important;
  color: #003458 !important;
}

.ui.circular.labels .label.labelskill {
  padding-right: 2em !important;
  padding-left: 2em !important;
}

sup {
  top: -1.5em;
}

.infoi {
  border-radius: 50% !important;
  font-size: 10px !important;
  padding: 3px !important;
}

.boxdisabled {
  box-shadow: none !important;
  background: #f1f4f8 !important;
  border-radius: 0% !important;
  border: none !important;
}

.ui.red.button:disabled {
  cursor: not-allowed !important;
}
/* 
#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
} */

/* width */
.workscroll::-webkit-scrollbar {
  width: 5px !important;
}

.scroll::-webkit-scrollbar {
  width: 5px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.workscroll ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
} */

.ql-editor {
  min-height: 400px !important;
}
.inputborder {
  /* background: #ffffff; */
  border: 1px solid rgba(0, 52, 88, 0.4);
  border-radius: 2px;
}

.ui.accordion .title:not(.ui) {
  display: flex !important;
}

/* .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
} */

.hover:hover {
  transform: scale(1.3);
  transition: 0.3s;
}

.bluecol u {
  font-family: "Matter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.pencil-icon {
  /* display: none; */
  border-radius: 3px;
  position: absolute;
  left: 8.2%;
  top: 3%;
  background: white;
  height: 4%;
  width: 2%;
}
.pencil-icon:hover {
  display: inline-block;
}

.share-link {
  justify-content: space-around;
}

.Stage {
  position: absolute;
  top: 38px;
  left: 115px;
  width: 150px;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Matter";
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.basic-bg {
  background: #f9f9f9;
  box-shadow: 0.3px 0.3px 4.9px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.icon-space {
  padding-right: 5px !important;
}

.ui.breadcrumb a {
  font-family: "Matter";
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 29px !important;
  /* identical to box height */
  color: white !important;
  text-decoration-line: underline !important;
}

.ui.breadcrumb .divider {
  color: white !important;
  font-size: 18px !important;
}
/* .ui.dropdown.dropdown.icon.drop-icon {
  font-size: 20px !important;
} */

.drop-icon.ui.dropdown.icon.button > .dropdown.icon {
  position: relative;
  width: auto;
  font-size: 30px;
  /* margin: 0em 0em 0em 1em; */
  height: 100% !important;
  padding-left: 10% !important;
  line-height: unset !important;
}

/* font-size: 35px;
margin-left: 10px; */

.add-modal-card {
  border: 1px dashed #003458 !important;
  box-shadow: none !important;
  padding: 10px !important;
  border-radius: 0px !important;
}

.article {
  height: 60px;
  background-repeat: no-repeat;
  /* background-position: center;
  background-size: cover;
  display: grid;
  place-items: center; */
  padding: 10% 5%;
}

.article-title {
  font-size: 12px;
  font-weight: 300 !important;
  color: white;
  /* text-align: center; */
}

.typeof {
  pointer-events: none !important;
}

.talent-modal {
  margin-top: 100px !important;
  width: 90% !important;
}

.bread-crum {
  top: 15px;
  position: absolute;
  left: 6%;
}

@media (max-width: 767px) {
  .margin-top-map {
    margin-top: 28px !important;
  }
  .margin-top2 {
    margin-top: 2rem !important;
  }
  .talent-insight {
    margin-top: 12px !important;
  }
}

@media (max-width: 991px) {
  .margin-top2 {
    margin-top: 2rem !important;
  }
}

/* @media (max-width: 500px) {
  .Stage {
    left: 20% !important;
    font-size: 14px;
    left: 140px;
  }
} */

@media (max-width: 767px) {
  .pencil-icon {
    border-radius: 3px;
    position: absolute;
    left: 55px;
    top: 1%;
    background: white;
    height: 3%;
    width: 25px;
  }
  .chartjs-render-monitor {
    position: relative;
    margin: auto;
    height: 70vh !important;
    /* width: 80vw !important; */
  }
  .job-container {
    padding: 0% !important;
  }
  .job-table {
    display: flex;
    justify-content: flex-end;
  }
  .job-table-head,
  .job-table-body {
    width: 50% !important;
  }
  .job-table-body {
    width: 50% !important;
  }
  .job-tr {
    padding-top: 0px !important;
  }
}
@media (max-width: 1030px) {
  .job-tr {
    display: grid !important;
  }
  .tbhead,
  .tbcell {
    border-bottom: none !important;
    padding: 0px 11px 13px 0px !important;
  }
  .job-table {
    display: flex;
    justify-content: flex-end;
    gap: 16%;
  }
  .job-company {
    margin-top: 2% !important;
  }
}

#job-company {
  margin-top: -5% !important;
  text-align: end !important;
}

@media (max-width: 425px) {
  .ui.grid > .row > [class*="eight wide"].column.job-grid {
    width: 100% !important;
  }

  .job-table {
    padding-top: 5% !important;
    gap: 2%;
  }
}
@media (max-width: 1130px) and (min-width: 1030px) {
  .job-container {
    padding: 0% 4% !important;
  }
}

@media (min-width: 768px) {
  .hr-line {
    width: 64% !important;
  }
}

@media (max-width: 425px) {
  .hr-line {
    width: 35% !important;
  }
}
@media (max-width: 365px) {
  .hr-line {
    width: 25% !important;
  }
}

@media (max-width: 600px) {
  .exit-talentcard {
    display: none !important;
  }
}
.chartjs-render-monitor {
  position: relative;
  margin: auto;
  height: 60vh !important;
  /* width: 80vw !important; */
}

/* .exit-modal{
  margin-top: 8% !important;
  width: 90% !important;
} */

.add-comment-card {
  /* background: #f4f4f4 !important; */
  border-radius: 34px 3px 3px 3px !important;
  transform: matrix(-1, 0, 0, 1, 0, 0) !important;
}

.add-comment-input {
  background: #003458 !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25) !important;
  border-radius: 25px 7px 7px 7px !important;
  color: white !important;
  height: 50px !important;
}

.add-comment-send-img {
  margin: 11px auto !important;
  height: 28px !important;
}

.with-hr-line {
  display: flex;
  align-items: center;
}

.with-hr-line::after {
  content: "";
  flex: 1;
  margin-left: 1rem;
  height: 1px;
  background-color: #003458;
  opacity: 0.2;
}

#navbar {
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
}

#hide {
  position: fixed;
  top: 50vh;
  left: 40vh;
}

.User-profile {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border-style: solid;
  top: -75px;
  left: 30px;
  border-color: #ffffff;
  box-shadow: 0 0 0px 0px #b8b8b8;
  position: absolute;
}

.User-profile img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.pencil-icon {
  position: absolute;
  top: 5px;
  left: 60px;
  /* border: 1px solid; */
  border-radius: 50%;
  /* padding: 11px; */
  height: 25px;
  width: 25px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: cornflowerblue;
  box-shadow: 0 0 6px 1px #b8b8b8;
}

.pencil-icon.i.icon {
  height: auto !important;
}

.wrapper {
  display: flex;
  align-items: center;
}

.line {
  flex-grow: 1;
  margin: 0 10px;
  height: 1px;
  background-color: #003458;
  opacity: 0.2;
}

@media (max-width: 615px) {
  .wrapper {
    display: grid !important;
  }
}

.blurring.dimmable > .dimmer {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blur {
  /* background: url("https://images.unsplash.com/photo-1486723312829-f32b4a25211b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=") no-repeat center center fixed; */
  background-size: cover;
  overflow: hidden;
  filter: blur(3px);
  height: 80%;
  user-select: none;
  cursor: not-allowed;
  pointer-events: none;
  /* position: absolute; */
  /* height: 300px; */
  /* top: -50px;
  left: -50px;
  right: -50px;
  bottom: -50px; */
}

/* .half-bg-blue{
  background-color: #d9f2ff;
  height: 50vh !important;
  bottom: 0px !important;
  position: absolute;
} */
 .half-bg-blue{
  background: linear-gradient(to bottom, white 50%, #F0F4FA 50%);
 }

 @media (max-width: 600px) {
  .signup-form{
    margin-top: 18% !important;
  }
  .signin-form{
    margin-top: 18% !important;
  }
}

.editanddelete {
  cursor: pointer;
}

.editanddelete:hover {
  color: blue;
  cursor: pointer;
}