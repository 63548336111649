.signin-form {
  width: 600px;
  max-width: 100%;
}



.hire-text{
  color: var(--Blue, var(--Blue, #003458));
font-family: "Public Sans";
/* font-size: 52px; */
font-size: 30px;
/* font-style: normal; */
font-weight: bolder;
/* line-height: 120%; 62.4px */
text-transform: uppercase;
margin-bottom: 0.5em;
}
.signin-form{
  background: #FFF !important;
  width: 100% !important;
}
.signup-form{
  background: #FFF !important;
}
.signup-form-card{
border-radius: 16px !important;
border: 1px solid var(--Grey, #B3B3B3) !important;
background: #FFF !important;
box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
}
.sign-up-text-icon{
  float: left;
  margin: 10px;
}
.icon-wala-text{
  color: var(--Blue, var(--Blue, #003458));
  font-family: "Public Sans";
  /* font-size: 28px; */
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin: 0.5em;
  /* line-height: 145%; */
}
.custom-list{
  list-style-image: url("../../../images/svg/sign-up-form-icon.svg");
}
.man-img-text-1{
  color: var(--Blue, var(--Blue, #003458));
  text-align: right;
  font-family: "Anderson Grotesk";
  /* font-size: 15px; */
  font-style: normal;
  font-weight: 700;
}
.man-img-text-2{
  color: var(--Blue, var(--Blue, #003458));
font-family: "Anderson Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
}
@media (max-width: 600px) {
  .first-grid-column{
    display: none !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #b3b3b3;

  /* font-family: Matter; */
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #b3b3b3;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.page-title {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #3d3737;
  margin-top: 20px !important;
}
#password {
  padding: 1.2rem !important;
  background: white !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

@media (max-width: 1000px) {
  /* Your CSS rules for screens with a maximum width of 600px go here */
  .signin-form {
    width: 1000px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    /* background-color: red !important; */
  }
  /* Add more CSS rules as needed */
}

.google-linked-button {
  width: 100%;
}
