.what-do-we-offer-text{
font-family: Matter;
font-size: 26px;
font-weight: 600;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
}
.back-button{
    visibility: hidden;
    /* background-color: #003458; */
}

.team-member-initials{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 5px solid white;
    background-color: #21C8AA;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}
.logo{
 border: 5px solid #21C8AA;
}
.pencil-icon-2{
    /* position: relative; */
    top: 160px;
    left: 115px;
    /* top: 30px; */
}

.hire-by-role-text{
font-family: Matter;
font-size: 22px;
font-weight: bold;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
margin-left: 10px;
margin-top: -3px;
}
.left-icons{
 margin-left: 10px !important;
}
.left-grey-text{
    font-family: Matter;
font-size: 18px;
margin: 10px !important;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #645B5B;
}
.about-you-text{
    font-family: Matter;
font-size: 28px !important;
font-weight: 500;
line-height: 43px;
letter-spacing: 0em;
text-align: left;
color: #003458;
}
.there-are-no-jobs-by-you-text{
    font-family: Matter;
font-size: 28px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #B3B3B3;
}
.what-will-your-preference-be-text{
font-family: Matter;
font-size: 28px;
font-weight: 500;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #645B5B;
}
.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.choose-btn{
 width: 166px;
 margin: 20px !important;
 font-size: 17px !important;
}
.choose-btns-div{
margin-left: 90px;
}
.or-text{
color: #645B5B;
font-size: 24px;
position: relative;
top: 25px;
}


.initial-client-logo{
    background-color: #003458;
    border-radius: 999px;
    color: white;
    width: 56px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
 font-weight: 600;
}
.div-of-logo-and-name{
    margin-bottom: 10px;
}
.client-name-text{
    color: var(--Blue, var(--Blue, #003458));
    font-family: Matter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
.initial-inside-logo{
 /* font-size: 43px;
 font-weight: 600; */
}
.not-sidebar {
    width: calc(100% - 400px) !important;
    position: absolute;
    right: 0px;
}
@media only screen and (max-width: 600px) {
    .sidebar-mobile{
        display: none;
    }
    .not-sidebar{
        display: contents;
    }
    .back-button{
        visibility: visible;
    }
  }
.branding-div{
    border-radius: 8px;
    background: #C8EBFF;
    /* display: flex; */
}
.branding-div-text{
    color: var(--Text, #645B5B);
    font-family: Matter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.branding-icon{
    font-size: 32px !important;
    color: #645B5B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-company-page-btn{
    border-radius: 8px !important;
border: 2px solid var(--Green, #21C8AA) !important;
color: #21C8AA !important;
background: #FFF !important;
}

.add-btn{
    color: #21C8AA !important;
    background: white !important;
    border-radius: 100% !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
}
.minus-btn{
color: red !important;
background: white !important;
border-radius: 100% !important;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
}