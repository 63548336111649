.ui.grid > .row {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding: 0rem;
    padding-top: 0.6rem !important;
    padding-bottom: 1rem;
}

@media (max-width: 350px) {
    .button {
        font-size: 1rem;
        padding: 0.9rem 0;
    }
}

/* Define the spinning animation */
/* Define the spinning animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Loader container */
  .custom-loader {
    width: 30px;
    height: 30px;
    border: 6px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top: 6px solid white; /* Green color for top border */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    margin: auto;
  }
  
  