.hire-text{
    color: var(--Blue, var(--Blue, #003458));
  font-family: "Public Sans";
  /* font-size: 52px; */
  font-size: 2.5vw !important;
  /* font-style: normal; */
  font-weight: bolder;
  /* line-height: 120%; 62.4px */
  text-transform: uppercase;
  margin-bottom: 0.5em;
  }
  /* .card-description{
    font-size: 1vw !important;
    line-height: 25px;
  } */
  .signup-form-card{
  border-radius: 16px !important;
  border: 1px solid var(--Grey, #B3B3B3) !important;
  background: #FFF !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
  }
  .sign-up-text-icon{
    float: left;
    margin: 10px;
  }
  .icon-wala-text{
    color: var(--Blue, var(--Blue, #003458));
    font-family: "Public Sans";
    /* font-size: 28px; */
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    margin: 0.5em;
    /* line-height: 145%; */
  }
  .custom-list{
    list-style-image: url("../../../../images/svg/sign-up-form-icon.svg");
  }
  .man-img-text-1{
    color: var(--Blue, var(--Blue, #003458));
    /* text-align: right; */
    font-family: "Matter";
    /* font-size: 18px !important; */
    font-style: normal;
    font-weight: 700;
  }
  .man-img-text-2{
    color: var(--Blue, var(--Blue, #003458));
  font-family: "Matter";
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  }
  @media (max-width: 600px) {
    .first-grid-column{
      display: none !important;
    }
  }

  /* @media (min-width: 1500px) {
    .first-grid-column{
      display: none !important;
    }
  } */