.right > .active > .item {
  color: #b2d431;
}
#PlacesAutocomplete__autocomplete-container {
  z-index: 100000;
}

.css-yk16xz-control {
  background: #f8f8f8 !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px !important;
}

.ui.vertical.menu.sidebar-card {
  height: fit-content !important;
}

.ui.input > input {
  background: #f8f8f8 !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  /* border-radius: 6px !important; */
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #21c8aa !important;
}

.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21c8aa !important;
}

.filter-by.ui.basic.button {
  border: 1px solid rgba(0, 52, 88, 0.3) !important;
  border-radius: 6px !important;
  color: #003458 !important;
}

.sidebar-card.ui.vertical.menu.sidebar-card {
  height: 90vh !important;
}

.add-to-job.ui.button:disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.single-button.selected {
  background-color: #21C8AA !important;
  color: white !important;
}

@media (max-width: 615px) {
  .talentpool-addjob-btn {
    display: flex !important;
    padding-top: 10px;
  }
}

@media (min-width: 1100px) {
  .filterssss {
    display: block !important;
  }
}

/* @media (max-width:1101px) {
  .filterssss{
    display:none !important
  }
} */


