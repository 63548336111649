.initial-client-logo{
    background-color: #003458;
    border-radius: 999px;
    color: white;
    width: 40px !important;
    height: 40px !important; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    border-radius: 8px;
}
.div-of-logo-and-name{
    margin-bottom: 10px;
}
.client-name-text{
    color: var(--Blue, var(--Blue, #003458));
    font-family: Matter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
.initial-inside-logo{
 /* font-size: 43px;
 font-weight: 600; */
 font-size: 22px !important;
}
.branding-div{
    border-radius: 8px;
    /* display: flex; */
}
.branding-div-text{
    color: var(--Text, #645B5B);
    font-family: Matter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.branding-icon{
    font-size: 24px !important;
    color: #645B5B;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -------------------------------------------------- */
.what-do-we-offer-text{
    font-family: Matter;
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    .hire-by-role-text{
    font-family: Matter;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
    margin-top: -3px;
    }
    .left-icons{
     margin-left: 10px !important;
    }
    .left-grey-text{
        font-family: Matter;
    font-size: 18px;
    margin: 10px !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #645B5B;
    }
    .about-you-text{
        font-family: Matter;
    font-size: 36px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    color: #003458;
    }
    .there-are-no-jobs-by-you-text{
        font-family: Matter;
    font-size: 28px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #B3B3B3;
    }
    .what-will-your-preference-be-text{
    font-family: Matter;
    font-size: 28px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #645B5B;
    }
    .center{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
    }
    .choose-btn{
     width: 166px;
     margin: 20px !important;
     font-size: 17px !important;
    }
    .choose-btns-div{
    margin-left: 90px;
    }
    .or-text{
    color: #645B5B;
    font-size: 24px;
    position: relative;
    top: 25px;
    }