.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.resume-edit-button{
  justify-content: end !important;
}

@media (max-width: 400px) {
  .resume-edit-button{
    justify-content: left !important;
  }

}