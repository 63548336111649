.talent-card-contaiiner {
  /* border-radius: 11px !important; */
  background: #001a2d !important;
  padding: 70px 3% !important;
}
a {
  cursor: pointer !important;
}

.exit-modal {
  /* display: flex !important;
  right: 7% !important;
  top: 60px !important; */
  display: flex !important;
  right: 2% !important;
  top: 10px !important;
  position: absolute;
}
.talent-card-grid {
  border-radius: 11px !important;
}
.talent-card-grid.ui.grid > .row {
  border-radius: 11px !important;
  background: #fff !important;
}
.card-design {
  border-radius: 8px !important;
  border-top: 2px solid #0e4b76 !important;
  background: #fff !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
}

.talent-card-container {
  /* border-radius: 11px !important; */
  padding: 30px 3%;
}

.basic-info {
  border-radius: 8px !important;
  background: #e8e8e8a8 !important;
  padding: 5% !important;
}

.profile-photo {
  width: 80px !important;
  /* height: 80px !important; */
}
@media (max-width:1500px) and (min-width:1150px) {
  .profile-photo {
    width: 130px !important;
    /* height: 80px !important; */
  }
}

.overview-head {
  display: flex !important;
  justify-content: space-between !important;
  color: #003458 !important;
}

.Edit {
  height: 16px !important;
  margin: 0 !important;
}

.preferences {
  color: var(--blue, #003458);
  font-family: Matter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

ul.ui.horizontal.bulleted.list li::before,
.ui.horizontal.bulleted.list > .item::before {
  color: #21c8aa !important;
}
.bullet-point {
  color: #645b5b !important;
  font-size: 12px !important;
}

.ui.tabular.menu .active.item {
  background: none #21c8aa !important;
  color: rgba(0, 0, 0, 0.95);
  border-top-width: 1px;
  border-color: #d4d4d5;
  font-weight: bold;
  margin-bottom: -1px;
  box-shadow: none;
  border-radius: 16px 16px 0px 0px !important;
  border-radius: 0.28571429rem 0.28571429rem 0px 0px !important;
}
.ui.tabular.menu + .attached:not(.top).segment,
.ui.tabular.menu + .attached:not(.top).segment + .attached:not(.top).segment {
  background: none #e8e8e8a8 !important;
  border: none !important;
}

.ui.tabular.menu .item {
  background: #dedbdb3b !important;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px !important;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  padding: 0.92857143em 1.42857143em;
  color: rgba(0, 0, 0, 0.87);
}

.ui.tabular.menu .active.item {
  background: none #ffffff;
  color: white !important;
  border-top-width: 1px;
  border-color: #d4d4d5;
  font-weight: bold;
  margin-bottom: -1px;
  box-shadow: none;
  border-radius: 6px 16px 0px 0px !important;
}

.ui.basic.label {
  margin-left: 5px !important;
  background: none #ffffff;
  border: 1px solid #21c8aa !important;
  border-radius: 8px;
  color: #21c8aa !important;
  box-shadow: none;
}
.ui.attached.tabular.menu {
  border: none !important;
}

/* Circular progress Bar */
.CircularProgressbar-path {
  stroke: #21c8aa !important;
}
/* .CircularProgressbar-trail {
  stroke: gray;
} */
.CircularProgressbar-text {
  fill: #003458 !important;
}
/* .CircularProgressbar-background {
  fill: green;
} */

.CircularProgressbar {
  height: 100px !important;
  width: 100px !important;
}

.progress {
  text-align: center !important;
}

/* .roundcheckbox.ui.checkbox .box:before, .ui.checkbox label:before{
border: none !important;
} */

.roundcheckbox.ui.checkbox .box:after,
.ui.checkbox label:after {
  border-radius: 50% !important;
}

.ui.tabular.menu + .attached:not(.top).segment,
.ui.tabular.menu + .attached:not(.top).segment + .attached:not(.top).segment {
  padding: 16px !important;
}

.heading-fontSize {
  font-size: 16px !important;
  color: #003458 !important;
}

@media (max-width: 768px) {
  .CircularProgressbar {
    height: 80px !important;
    width: 80px !important;
  }
}

@media (min-width: 768px) {
  .left-basic-info {
    position: sticky !important;
    top: 0px !important;
    height: fit-content !important;
    /* background-color: #e8e8e8a8 !important; */
  }
}

@media (max-width: 768px) {
  .left-basic-info {
    background-color: #e8e8e8a8 !important;
  }
  .action-buttons {
    position: fixed !important;
    bottom: 0px !important;
    z-index: 21 !important;
    background: white !important;
    border-radius: 20px 20px 0px 0px !important;
    width: -webkit-fill-available !important;
    height: 65px !important;
    text-align: center !important;
    left: 0 !important;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .talent-card-contaiiner {
    padding: 0 !important;
  }
  .basic-info {
    padding: 0 !important;
  }
  .info-tabs {
    padding: 0 !important;
  }
  .profile-info {
    position: fixed;
    z-index: 21;
    background: white;
    width: -webkit-fill-available;
    padding-top: 12px;
  }
  .job-detail {
    margin-top: 0px;
  }
  .cardshow {
    display: block;
    margin-top: 140px;
  }
  .qratafit {
    display: none !important;
  }
  .feeds {
    position: fixed;
    bottom: 70px;
    z-index: 1;
    right: 18px;
    padding: 10px !important;
    background: white !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  }
  /* .ui.attached.tabular.menu {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    display: none;
  } */
  .addto-job {
    position: absolute;
    left: 35%;
    top: 15px;
  }
  .job-action-btn {
    padding-top: 15px;
  }
  .mobile-basicInfo-card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
    background: white;
    border-radius: 10px;
  }
}

@media (min-width: 768px) {
  .action-buttons {
    position: absolute;
    top: -44px;
    right: 0px;
    display: flex;
    gap: 10px;
  }
  .feeds {
    position: absolute;
    top: -44px;
    left: 120px;
  }
  .cardshow {
    display: none !important;
  }
  /* .qratafit{
    display: block !important;
    } */
  .comm-trust {
    padding-top: 0px !important;
  }
}

.interDuration {
  display: flex;
  gap: 1.3rem;
}

.timeDuration {
  display: flex;
  gap: 1.9rem;
}

@media (max-width: 600px) {
  .interDuration,
  .timeDuration {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .interType,
  .duration,
  .datePicker {
    width: 100%;
  }
}
