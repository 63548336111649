.popup-card {
    user-select: text; /* Allows text selection */
    cursor: text; /* Changes cursor to text selection cursor */
  }
  
  .popup-card a {
    color: blue; /* Customize link color */
    text-decoration: underline; /* Underline links for better visibility */
  }
  
  .popup-card a:hover {
    text-decoration: none; /* Remove underline on hover for a cleaner look */
  }
  