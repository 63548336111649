.right > .active > .item {
  color: #21c8aa !important;
  font-weight: 600;
}

.side-nav-bar {
  background: rgb(0, 52, 88) !important;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1 !important;
  align-items: center !important;
  /* padding: 0 20px; */
  height: fit-content !important;
}

.mobile-sidebar.ui.menu .item {
  color: white !important;
}
.mobile-sidebar.ui.accordion .title {
  color: white !important;
}

.mobile-sidebar {
  background: #003458 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 0px 24px 24px !important;
  width: 100% !important;
  padding-left: 1rem !important;
}

.mobile-sidebar.ui.right.overlay.sidebar {
  margin-top: 64px !important;
  height: fit-content !important;
  border-radius: 0px 0px 24px 24px !important;
}

.mobile-sidebar.ui.vertical.menu .active.item {
  color: #21c8aa !important;
  font-family: Matter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navbar-icon {
  display: grid !important;
  margin: auto !important;
}

.navbar-icon > .icon {
  margin: auto !important;
  height: 20px !important;
  width: 20px !important;
}

.need-help {
  text-align: center !important;
}
.need-help > div > .grid > .row > .column > .button {
  box-shadow: rgb(0, 52, 88) 0px 0px 0px 1px !important;
  color: rgb(0, 52, 88) !important;
  background: rgb(240, 244, 255) !important;
  border-radius: 8px !important;
}
