.signup-form {
  width: 600px;
  max-width: 100%;
}
.create-your-account-text {
  font-family: Matter;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
}

.hire-text{
  color: var(--Blue, var(--Blue, #003458));
font-family: "Public Sans";
/* font-size: 52px; */
font-size: 30px;
/* font-style: normal; */
font-weight: bolder;
/* line-height: 120%; 62.4px */
text-transform: uppercase;
margin-bottom: 0.5em;
}
.signup-form-card{
border-radius: 16px !important;
border: 1px solid var(--Grey, #B3B3B3) !important;
background: #FFF !important;
box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
}
.sign-up-text-icon{
  float: left;
  margin: 10px;
}
.icon-wala-text{
  color: var(--Blue, var(--Blue, #003458));
  font-family: "Public Sans";
  /* font-size: 28px; */
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin: 0.5em;
  /* line-height: 145%; */
}
.custom-list{
  list-style-image: url("../../../images/svg/sign-up-form-icon.svg");
}
.man-img-text-1{
  color: var(--Blue, var(--Blue, #003458));
  text-align: right;
  font-family: "Anderson Grotesk";
  /* font-size: 15px; */
  font-style: normal;
  font-weight: 700;
}
.man-img-text-2{
  color: var(--Blue, var(--Blue, #003458));
font-family: "Anderson Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
}
@media (max-width: 600px) {
  .first-grid-column{
    display: none !important;
    /* margin-top: 10% !important; */
  }
}

@media (max-width: 1000px) {
  .first-grid-column{
    display: none !important;
  }

  /* Your CSS rules for screens with a maximum width of 600px go here */
  .signin-form {
    width: 100% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 10% !important; */
    /* padding: 10px; */
    /* background-color: red !important; */
  }
  /* Add more CSS rules as needed */
}

@import url(https://fonts.googleapis.com/css?family=Matter:400,100,200,300);
* {
  margin: 0;
  padding: 0;
}

a {
  color: #666;
  text-decoration: none;
}
a:hover {
  color: #4fda8c;
}

input {
  font: 16px/26px "Raleway", sans-serif;
  /* border: 1px solid #c4c4c4 !important; */
}

/* for half page to be blue  */
/* body {
    color: #666;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom, #ffffff 50%, #F0F4FF 50%) !important;
    font: 16px/26px "Raleway", sans-serif; 
  } */

.form-wrap {
  background-color: #fff;
  margin-top: 50%;
  width: 70%;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0px 1px 8px #bebebe;
  -webkit-box-shadow: 0px 1px 8px #bebebe;
  -moz-box-shadow: 0px 1px 8px #bebebe;
}
#user_pass {
  position: relative;
}
.pass-div {
  position: relative;
}

.toggle-password {
  position: absolute;
  /* float: right; */
  cursor: pointer;
  right: 20px;
  /* margin-right: 10px; */
  margin-top: 17px;
  z-index: 2;
}

.page-title {
  text-align: center;
  /* font-weight: 600; */
  /* font-family: Matter; */
  font-size: 25px;
  font-weight: bold;
  /* line-height: 38px; */
  letter-spacing: 0em;
  text-align: center;
  color: #3d3737;
  margin-top: 10px !important;
}

.form-wrap .tabs {
  overflow: hidden;
  border-radius: 10px;
  font-size: 26px;
}

.oauth-btn {
  border-radius: 8px;
  border: 1px solid var(--Text, #3d3737);
  background: #fff;
  padding: 10px;
  font-weight: 400;
  width: 100%;
  width: 307px;
  /* color: white; */
}
.oauth-btn-img {
  width: 24px;
}

.oauth-btn-1 {
  margin-top: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  color: black;
}
.oauth-btn-2 {
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  background-color: #0a66c2;
  color: white;
}
.tab-title {
  /* font-family: Matter; */
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.input {
  /* height: 73px; */
  border-radius: 8px;
  border: 2px solid #d9d9d9;
}

.forgot-password-link {
  float: right;
  color: #003458;
  margin-bottom: 24px;
}
.text-before-link {
  /* font-family: Matter; */
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px !important;
}

.not-this-link {
  color: #21c8aa;
  font-weight: bold;
  size: 20px;
  line-height: 24px;
  text-decoration: underline;
}
.not-this-link:hover {
  color: #21c845;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #b3b3b3;

  /* font-family: Matter; */
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #b3b3b3;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.tab-title .active:hover {
  text-decoration: none;
}

.form-wrap .tabs h3 {
  float: left;
  width: 50%;
}
.form-wrap .tabs h3 a {
  padding: 0.5em 0;
  text-align: center;
  font-weight: 400;
  background-color: #e6e7e8;
  display: block;
  color: #666;
}

.form-wrap .tabs h3 a.active {
  background-color: #fff;
  color: #21c8aa;
}

.form-wrap .tabs-content {
  padding: 1.5em;
}
.form-wrap .tabs-content div[id$="tab-content"] {
  display: none;
}
.form-wrap .tabs-content .active {
  display: block !important;
}
.form-wrap form .input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  padding: 0.8em 0 10px 0.8em;
  border: 1px solid #cfcfcf;
  outline: 0;
  display: inline-block;
  margin: 0 0 0.8em 0;
  padding-right: 2em;
  width: 100%;
}
.form-wrap form .button {
  width: 100%;
  padding: 0.8em 0 10px 0.8em;
  background-color: #21c8aa;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
}
.form-wrap form .button:hover {
  background-color: #4fda8c;
}
.form-wrap form .checkbox {
  visibility: hidden;
  padding: 20px;
  margin: 0.5em 0 1.5em;
}
.form-wrap form .checkbox:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}
.form-wrap form label[for] {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.form-wrap form label[for]:before {
  content: "";
  position: absolute;
  border: 1px solid #cfcfcf;
  width: 17px;
  height: 17px;
  top: 0px;
  left: -14px;
}
.form-wrap form label[for]:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background-color: transparent;
  top: 4px;
  left: -10px;
  border: 3px solid #28a55f;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.form-wrap .help-text {
  margin-top: 0.6em;
}
.form-wrap .help-text p {
  text-align: center;
  font-size: 14px;
}

.fname {
  margin-right: 8px !important;
}
/* .lname{
    margin-left: 4px;
  } */
.small-text-before-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-top: 5px;
    margin-bottom: 5px; */
}

.small-text-before-btn span {
  font-size: 14px;
  font-weight: bold;
  color: #003458;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
